import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Hero } from '../components/hero';
import classNames from 'classnames';
import { Link as DynamicLink} from '../components/links';
import { formatToGatsbyImage } from '../lib/graphql-helpers';
import "../styles/pages/newsroom.scss";

const Newsroom = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allSheet2Apievents {
        edges {
          node {
            Date
            Link
            Title
            Type
            Upcoming
          }
        }
      }
      allFile(
        filter: {
          extension: { regex: "/(jpg|png|svg)/" }
          relativeDirectory: { eq: "newsroom" }
        }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `);
  const {
    allSheet2Apievents: { edges: allEvents },
    allFile: { edges: allImages },
  } = data;

  const images = formatToGatsbyImage({ images: allImages });
  
  const unsortedEvents = allEvents.slice(0, -1);
  
  const upcoming = unsortedEvents.filter(event => event.node.Upcoming === 'TRUE');
  
  const news = unsortedEvents.filter((event) => event.node.Upcoming !== "TRUE");

  const heroData = {
    title: "Newsroom",
    image: images["newsroomHero"],
    textColor: "black",
  };

  return (
    <div className="newsroom">
      <Hero data={heroData} />
      <section className="newsroom__index">
        <div className="newsroom__index__inner">
          <h3>Upcoming events</h3>
          <div className="newsroom__index__content">
            {upcoming.map((event) => {
              const { Date, Link, Title, Type } = event.node;
              const eventClasses = classNames(
                "newsroom__index__content__event",
                {
                  "newsroom__index__content__event--has-link": Link.length > 0,
                }
              );
              return (
                <div className={eventClasses}>
                  {Link.length > 0 ? <DynamicLink to={Link}>{Title}</DynamicLink> : <h4>{Title}</h4>}
                  <div>
                    <p>{Type}</p>
                    <p>{Date}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="newsroom__index">
        <div className="newsroom__index__inner">
          <h3>In the news</h3>
          <div className="newsroom__index__content">
            {news.map((event) => {
              const { Date, Link, Title, Type } = event.node;
              const eventClasses = classNames(
                "newsroom__index__content__event",
                {
                  "newsroom__index__content__event--has-link": Link?.length > 0,
                }
              );
              return (
                <div className={eventClasses}>
                  {Link?.length > 0 ? (
                    <DynamicLink to={Link}>{Title}</DynamicLink>
                  ) : (
                    <h4>{Title}</h4>
                  )}
                  <div>
                    {Type && <p>{Type}</p>}
                    {Date && <p>{Date}</p>}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Newsroom;