import React from "react";
import "./hero.scss";
import placeHolder from "../../images/locations-and-affiliates/locationsHero.svg";

const Hero = ({ data }) => {
  const { title, image, textColor } = data;

  const currentImage = image ? image : placeHolder;
  const titleColor = textColor ? {color: textColor} : {color: "white"};

  const heroImage = { backgroundImage: `url(${currentImage})` };
  return (
    <section style={heroImage} className="hero">
      <div className="hero__inner">
        <h1 style={titleColor}>{title}</h1>
      </div>
    </section>
  );
};

export default Hero;
